// import Home from './components/Home'

import AppHome from './components/AppHome'
import BustaPesa from "./components/BustaPesa";
// import Sport from "@/components/Sport.vue";

const Home = () => import(/* webpackChunkName: "match" */ './components/Home')
const Sport = () => import(/* webpackChunkName: "match" */ './components/Sport')

const FixturesBySport = () => import(/* webpackChunkName: "match" */ './components/FixturesBySport')
const Esport = () => import(/* webpackChunkName: "match" */ './components/Esport')
const Print = () => import(/* webpackChunkName: "match" */ './components/Print')
const PrintGame = () => import(/* webpackChunkName: "match" */ './components/PrintGame')
const Tips = () => import(/* webpackChunkName: "match" */ './components/Tips')
const Sharebet = () => import(/* webpackChunkName: "match" */ './components/Sharebet')
const Offers = () => import(/* webpackChunkName: "match" */ './components/Offers')
const Live = () => import(/* webpackChunkName: "match" */ './components/Live')
const Match = () => import(/* webpackChunkName: "match" */ './components/Match')
const Login = () => import(/* webpackChunkName: "account" */ './components/Login')
const Join = () => import(/* webpackChunkName: "account" */ './components/Join')
const Reset = () => import(/* webpackChunkName: "account" */ './components/Reset')
const Deposit = () => import(/* webpackChunkName: "account" */ './components/Deposit')
const DepositHowTo = () => import(/* webpackChunkName: "account" */ './components/DepositHowTo')
const Setting = () => import(/* webpackChunkName: "account" */ './components/Setting')
const Bets = () => import(/* webpackChunkName: "account" */ './components/Bets')
const Terms = () => import(/* webpackChunkName: "help" */ './components/Terms')
const Faq = () => import(/* webpackChunkName: "help" */ './components/Faq')
const Freebet = () => import(/* webpackChunkName: "match" */ "@/components/Freebet");
const Search = () => import(/* webpackChunkName: "match" */ "./components/Search");
const Countries = () => import(/* webpackChunkName: "match" */ "@/components/Countries");
const Outright = () => import(/* webpackChunkName: "match" */ "@/components/outright/Index");
const Today = () => import(/* webpackChunkName: "match" */ "@/components/Today");
const Tomorrow = () => import(/* webpackChunkName: "match" */ "@/components/Tomorrow");
const Upcoming = () => import(/* webpackChunkName: "match" */ "@/components/Upcoming");
const Share = () => import(/* webpackChunkName: "match" */ "@/components/Share");
const Invite = () => import(/* webpackChunkName: "match" */ "@/components/Invite");
const Help = () => import(/* webpackChunkName: "help" */ "./components/Help");
const Responsible = () => import(/* webpackChunkName: "help" */ "./components/Responsible");
const Booklet = () => import(/* webpackChunkName: "help" */ "./components/Booklet");
const Jackpot = () => import(/* webpackChunkName: "help" */ "./components/jackpot/Jackpot");
const Results = () => import(/* webpackChunkName: "help" */ "./components/Results");
const Livescore = () => import(/* webpackChunkName: "help" */ "./components/Livescore");
const Profile = () => import(/* webpackChunkName: "help" */ "./components/Profile");
const Gift = () => import(/* webpackChunkName: "help" */ "./components/Gift");
const FormulaOne = () => import(/* webpackChunkName: "help" */ "./components/FormulaOne");

//casino menus
const CasinoGames = () => import(/* webpackChunkName: "casino" */ "./components/casino/CasinoGames");
const Casino = () => import(/* webpackChunkName: "casino" */ "./components/casino/Casino");
const Virtuals = () => import(/* webpackChunkName: "casino" */ "./components/casino/Virtuals");
const LiveCasino = () => import(/* webpackChunkName: "casino" */ "./components/casino/Live");
const Rocketman = () => import(/* webpackChunkName: "casino" */ "./components/casino/Rocketman");
const Fastballs = () => import(/* webpackChunkName: "casino" */ "./components/casino/Fastballs");
const Smartsoft = () => import(/* webpackChunkName: "casino" */ "./components/casino/Smartsoft");
const Jetsafi = () => import(/* webpackChunkName: "casino" */ "./components/casino/Jetsafi");
const Booming = () => import(/* webpackChunkName: "casino" */ "./components/casino/Booming");
const Stp = () => import(/* webpackChunkName: "casino" */ "./components/casino/Stp");
const Onlyplay = () => import(/* webpackChunkName: "casino" */ "./components/casino/Onlyplay");
const MobileApp = () => import(/* webpackChunkName: "mobile" */ './components/MobileApp');
const Affiliate = () => import(/* webpackChunkName: "affiliates" */'./components/Affiliate');
const Referral = () => import(/* webpackChunkName: "referral" */'./components/Referral');
const TermsAffiliates = () => import(/* webpackChunkName: "termsaffiliates" */'./components/TermsAffiliates');
const BonusTnC = () => import(/* webpackChunkName: "bonustnc" */'./components/BonusTnC');
const CasinoHistory = () => import(/* webpackChunkName: "casinohistory" */'./components/CasinoHistory');



export const routes = [
    {
        path: '/',
        name: 'crash',
        component: Home,
        meta: {
            title: 'FalconBet | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts',
            metaTags: [
                {
                    name: 'title',
                    content: 'FalconBet | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    name: 'description',
                    content: 'FalconBet | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    name: 'keywords',
                    content: 'FalconBet, online betting , soccer,basketball, Bet, football bet online,place football bets online, place your bet,pre-game, live bet, how to build a bet.'
                },
                {
                    property: 'og:description',
                    content: 'FalconBet | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    property: 'twitter:description',
                    content: 'FalconBet | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    property: 'twitter:title',
                    content: 'FalconBet | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    property: 'og:title',
                    content: 'FalconBet | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    property: 'og:url',
                    content: 'FalconBet.co.ke'
                },
                {
                    property: 'og:type',
                    content: 'Website'
                }
            ]
        }
    },
    {
        path: "/sports/football",
        name: "football",
        component: Home,
    },
    {
        path: '/mobile-app',
        name: 'mobile-app',
        component: MobileApp,
    },
    {
        path: '/sport/:sport',
        name: 'sport',
        component: Sport,
        meta: {
            title: 'FalconBet | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts',
            metaTags: [
                {
                    name: 'title',
                    content: 'FalconBet | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    name: 'description',
                    content: 'FalconBet | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    name: 'keywords',
                    content: 'FalconBet, online betting , soccer,basketball, Bet, football bet online,place football bets online, place your bet,pre-game, live bet, how to build a bet.'
                },
                {
                    property: 'og:description',
                    content: 'FalconBet | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    property: 'twitter:description',
                    content: 'FalconBet | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    property: 'twitter:title',
                    content: 'FalconBet | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    property: 'og:title',
                    content: 'FalconBet | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    property: 'og:url',
                    content: 'FalconBet.co.ke'
                },
                {
                    property: 'og:type',
                    content: 'Website'
                }
            ]
        }
    },
    {
        path: '/',
        name: 'sport',
        component: Sport,
        meta: {
            title: 'FalconBet | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts',
            metaTags: [
                {
                    name: 'title',
                    content: 'FalconBet | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    name: 'description',
                    content: 'FalconBet | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    name: 'keywords',
                    content: 'FalconBet, online betting , soccer,basketball, Bet, football bet online,place football bets online, place your bet,pre-game, live bet, how to build a bet.'
                },
                {
                    property: 'og:description',
                    content: 'FalconBet | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    property: 'twitter:description',
                    content: 'FalconBet | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    property: 'twitter:title',
                    content: 'FalconBet | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    property: 'og:title',
                    content: 'FalconBet | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    property: 'og:url',
                    content: 'FalconBet.co.ke'
                },
                {
                    property: 'og:type',
                    content: 'Website'
                }
            ]
        }
    },
    {
        path: '/a',
        name: 'apphome',
        component: AppHome,
        meta: {
            title: 'FalconBet | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts',
            scrollTop: 0,
            metaTags: [
                {
                    name: 'title',
                    content: 'FalconBet | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    name: 'description',
                    content: 'FalconBet | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    name: 'keywords',
                    content: 'FalconBet, online betting , soccer,basketball, Bet, football bet online,place football bets online, place your bet,pre-game, live bet, how to build a bet.'
                },
                {
                    property: 'og:description',
                    content: 'FalconBet | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    property: 'twitter:description',
                    content: 'FalconBet | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    property: 'twitter:title',
                    content: 'FalconBet | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    property: 'og:title',
                    content: 'FalconBet | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    property: 'og:url',
                    content: 'FalconBet.co.ke'
                },
                {
                    property: 'og:type',
                    content: 'Website'
                }
            ]
        }
    },
    {
        path: '/search',
        name: 'search',
        component: Search,
        meta: {
            scrollTop: 0,
            title: 'FalconBet | Search Matches and bet now',
            metaTags: [
                {
                    name: 'title',
                    content: 'FalconBet | Search Matches and bet now'
                },
                {
                    name: 'description',
                    content: 'FalconBet | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    name: 'keywords',
                    content: 'FalconBet, online betting , soccer,basketball, Bet, football bet online,place football bets online, place your bet,pre-game, live bet, how to build a bet.'
                },
                {
                    property: 'og:description',
                    content: 'FalconBet | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    property: 'twitter:description',
                    content: 'FalconBet | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    property: 'twitter:title',
                    content: 'FalconBet |  Best online sport Betting In Africa'
                },
                {
                    property: 'og:title',
                    content: 'FalconBet |  Best online sport Betting In Africa'
                },
                {
                    property: 'og:url',
                    content: 'FalconBet.co.ke'
                },
                {
                    property: 'og:type',
                    content: 'Website'
                }
            ]
        }
    },
    {
        path: '/sports/:sport_name/:sport_id',
        name: 'fixturesbysport',
        component: FixturesBySport,
    },
    {
        path: '/live',
        name: 'live',
        component: Live,
        meta: {
            title: 'FalconBet | Fastest Live Betting',
            metaTags: [
                {
                    name: 'title',
                    content: 'FalconBet | Fastest Live Betting'
                },
                {
                    name: 'description',
                    content: 'FalconBet | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    name: 'keywords',
                    content: 'Live sports, bets, odds , multibet, jackpot,sport betting, best online sports betting'
                }
            ]
        }
    },
    {
        path: '/freebet',
        name: 'freebet',
        component: Freebet,
        meta: {
            title: 'FalconBet | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts',
            metaTags: [
                {
                    name: 'title',
                    content: 'FalconBet | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    name: 'description',
                    content: 'FalconBet | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    name: 'keywords',
                    content: 'FalconBet, online betting , soccer,basketball, Bet, football bet online,place football bets online, place your bet,pre-game, live bet, how to build a bet.'
                }
            ]
        }
    },
    {
        path: '/today',
        name: 'today',
        component: Today,
        meta: {
            title: 'FalconBet | Today | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts',
            metaTags: [
                {
                    name: 'title',
                    content: 'FalconBet | Today | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    name: 'description',
                    content: 'Best in-play betting company in Kenya that allows you to take your betting skills to a whole new level.'
                },
                {
                    name: 'keywords',
                    content: 'FalconBet, online betting , soccer,basketball, Bet, football bet online,place football bets online, place your bet,pre-game, live bet, how to build a bet.'
                }
            ]
        }
    },

    {
        path: '/tomorrow',
        name: 'tomorrow',
        component: Tomorrow,
        meta: {
            title: 'FalconBet | Tomorrow | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts',
            metaTags: [
                {
                    name: 'title',
                    content: 'FalconBet | Tomorrow | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    name: 'description',
                    content: 'Best in-play betting company in Kenya that allows you to take your betting skills to a whole new level.'
                },
                {
                    name: 'keywords',
                    content: 'FalconBet, online betting , soccer,basketball, Bet, football bet online,place football bets online, place your bet,pre-game, live bet, how to build a bet.'
                }
            ]
        }
    },

    {
        path: '/upcoming',
        name: 'upcoming',
        component: Upcoming,
        meta: {
            title: 'FalconBet | Upcoming | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts',
            metaTags: [
                {
                    name: 'title',
                    content: 'FalconBet Esports |Live matches | Betting'
                },
                {
                    name: 'description',
                    content: 'Best in-play betting company in Kenya that allows you to take your betting skills to a whole new level.'
                },
                {
                    name: 'keywords',
                    content: 'FalconBet, online betting , soccer,basketball, Bet, football bet online,place football bets online, place your bet,pre-game, live bet, how to build a bet.'
                }
            ]
        }
    },
    {
        path: '/esport',
        name: 'esport',
        component: Esport,
        meta: {
            title: 'FalconBet | Upcoming | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts',
            metaTags: [
                {
                    name: 'title',
                    content: 'FalconBet Esports |Live matches | Betting'
                },
                {
                    name: 'description',
                    content: 'Best in-play betting company in Kenya that allows you to take your betting skills to a whole new level.'
                },
                {
                    name: 'keywords',
                    content: 'FalconBet, online betting , soccer,basketball, Bet, football bet online,place football bets online, place your bet,pre-game, live bet, how to build a bet.'
                }
            ]
        }
    },
    {
        path: "/match/:status/:game_id/:name",
        name: "match",
        component: Match,
    },
    {
        path: '/share/:code',
        name: 'share',
        meta: {
            title: 'FalconBet | Join | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts',
            metaTags: [
                {
                    name: 'title',
                    content: 'FalconBet Esports |Live matches | Betting | Casino | Jackpots'
                },
                {
                    name: 'description',
                    content: 'Best in-play betting company in Kenya that allows you to take your betting skills to a whole new level.'
                },
                {
                    name: 'keywords',
                    content: 'FalconBet, online betting , soccer,basketball, Bet, football bet online,place football bets online, place your bet,pre-game, live bet, how to build a bet.'
                }
            ]
        },
        component: Share,
    },
    {
        path: '/invite/:code',
        name: 'invite',
        component: Invite,
    },
    {
        path: '/join',
        name: 'join',
        component: Join,
        meta: {
            title: 'Register',
            metaTags: [
                {
                    name: 'title',
                    content: 'Register'
                },
                {
                    name: 'description',
                    content: 'Register with us today and get Ksh 2000 Welcome Gift.'
                },
                {
                    name: 'keywords',
                    content: 'Register today, Register, FalconBet Registration'
                }
            ]
        },
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            title: 'Login',
            metaTags: [
                {
                    name: 'title',
                    content: 'Login'
                },
                {
                    name: 'description',
                    content: 'Enter your phone number and password below to login to your account'
                },
                {
                    name: 'keywords',
                    content: 'Log in, Play now, falconbet login, falconbet Registration'
                }
            ]
        },
    },
    {
        path: '/print',
        name: 'print',
        component: Print,
    },

    {
        path: '/print-game',
        name: 'print-game',
        component: PrintGame,
    },
    {
        path: '/deposit',
        name: 'deposit',
        component: Deposit,
        meta: {
            title: 'Depost To Your FalconBet Account',
            metaTags: [
                {
                    name: 'description',
                    content: 'Deposit the amount you wish to bet for and start online sports betting with FalconBet, the betst betting company in Africa.'
                },
                {
                    name: 'keyword',
                    content: 'Deposit, FalconBet, Betting, place a bet, how to bet, mobile betting, Withdrawal methods..'
                },
                {
                    property: 'og:description',
                    content: 'Deposit the amount you wish to bet for and start online sports betting with FalconBet, the betst betting company in Africa.'
                },
                {
                    property: 'twitter:description',
                    content: 'Deposit the amount you wish to bet for and start online sports betting with FalconBet, the betst betting company in Africa.'
                },
                {
                    property: 'twitter:title',
                    content: 'Depost To Your FalconBet Account'
                },
                {
                    property: 'og:title',
                    content: 'Depost To Your FalconBet Account'
                },
                {
                    property: 'og:url',
                    content: 'FalconBet.co.ke/deposit'
                },
                {
                    property: 'og:type',
                    content: 'Website'
                }
            ]
        }
    },
    {
        path: '/countries',
        name: 'country',
        component: Countries,
        meta: {
            title: 'Countries FalconBet Is Available',
            metaTags: [
                {
                    name: 'description',
                    content: 'FalconBet is available in these countries Africa hence you can play your esports or bet with FalconBet in your country or in eather of these other countries.'
                },
                {
                    name: 'keywords',
                    content: 'FalconBet, online betting , soccer,basketball, Bet, football bet online.'
                },
                {
                    property: 'og:description',
                    content: 'FalconBet is available in these countries Africa hence you can play your esports or bet with FalconBet in your country or in eather of these other countries.'
                },
                {
                    property: 'twitter:description',
                    content: 'FalconBet is available in these countries Africa hence you can play your esports or bet with FalconBet in your country or in eather of these other countries.'
                },
                {
                    property: 'twitter:title',
                    content: 'Countries FalconBet Is Available'
                },
                {
                    property: 'og:title',
                    content: 'Countries FalconBet Is Available'
                },
                {
                    property: 'og:url',
                    content: 'FalconBet.co.ke/countries'
                },
                {
                    property: 'og:type',
                    content: 'Website'
                }
            ]
        }
    },
    {
        path: '/outrights/:type',
        name: 'outright',
        component: Outright,
        meta: {
            title: 'FalconBet | Outright Events available Available',
            metaTags: [
                {
                    name: 'description',
                    content: 'FalconBet is available in these countries Africa hence you can play your esports or bet with FalconBet in your country or in eather of these other countries.'
                },
                {
                    name: 'keywords',
                    content: 'FalconBet, online betting , soccer,basketball, Bet, football bet online.'
                },
                {
                    property: 'og:description',
                    content: 'FalconBet is available in these countries Africa hence you can play your esports or bet with FalconBet in your country or in eather of these other countries.'
                },
                {
                    property: 'twitter:description',
                    content: 'FalconBet is available in these countries Africa hence you can play your esports or bet with FalconBet in your country or in eather of these other countries.'
                },
                {
                    property: 'twitter:title',
                    content: 'Countries FalconBet Is Available'
                },
                {
                    property: 'og:title',
                    content: 'Countries FalconBet Is Available'
                },
                {
                    property: 'og:url',
                    content: 'FalconBet.co.ke/countries'
                },
                {
                    property: 'og:type',
                    content: 'Website'
                }
            ]
        }
    },
    {
        path: '/outright',
        name: 'outright',
        component: Outright,
        meta: {
            title: 'Outrights FalconBet Is Available',
            metaTags: [
                {
                    name: 'description',
                    content: 'FalconBet is available in these countries Africa hence you can play your esports or bet with FalconBet in your country or in eather of these other countries.'
                },
                {
                    name: 'keywords',
                    content: 'FalconBet, online betting , soccer,basketball, Bet, football bet online.'
                },
                {
                    property: 'og:description',
                    content: 'FalconBet is available in these countries Africa hence you can play your esports or bet with FalconBet in your country or in eather of these other countries.'
                },
                {
                    property: 'twitter:description',
                    content: 'FalconBet is available in these countries Africa hence you can play your esports or bet with FalconBet in your country or in eather of these other countries.'
                },
                {
                    property: 'twitter:title',
                    content: 'Countries FalconBet Is Available'
                },
                {
                    property: 'og:title',
                    content: 'Countries FalconBet Is Available'
                },
                {
                    property: 'og:url',
                    content: 'FalconBet.co.ke/countries'
                },
                {
                    property: 'og:type',
                    content: 'Website'
                }
            ]
        }
    },
    {
        path: '/setting',
        name: 'setting',
        component: Setting,
        meta: {
            title: 'My Account | FalconBet | Best online sport betting |Freebet',
            metaTags: [
                {
                    name: 'description',
                    content: 'My FalconBet account - best online sport betting in africa where you can win a lot of prizes from our live bets,multi bets, daily jackpot and many more.'
                },
                {
                    name: 'keywords',
                    content: 'Freebet, best online sports betting, online games,live bets, bets , odds, sports betting'
                }
            ]
        }
    },
    {
        path: '/my-account',
        name: 'my-account',
        component: Setting,
        meta: {
            title: 'My Account | FalconBet | Best online sport betting |Freebet',
            metaTags: [
                {
                    name: 'description',
                    content: 'My FalconBet account - best online sport betting in africa where you can win a lot of prizes from our live bets,multi bets, daily jackpot and many more.'
                },
                {
                    name: 'keywords',
                    content: 'Freebet, best online sports betting, online games,live bets, bets , odds, sports betting'
                }
            ]
        }
    },
    {
        path: '/my-bets',
        name: 'bets',
        component: Bets,
        meta: {
            title: 'FalconBet - My Bets',
            metaTags: [
                {
                    name: 'description',
                    content: 'View all your bets, pending bets, resulted bets'
                }
            ]
        }
    },
    {
        path: '/terms',
        name: 'terms',
        component: Terms,
        meta: {
            title: 'FalconBet Terms & Conditions | Responsible Gaming |Sports betting: rules',
            metaTags: [
                {
                    name: 'description',
                    content: 'These are the FalconBet betting terms and conditions (Sports betting: rules)on how to place your bet while you follow favorite sports in the world\'s top League.'
                },
                {
                    name: 'keywords',
                    content: 'Terms & conditions, Betting, winner, 1st half, Both team to score, half time , full time, sport betting,live sports betting,online betting,bet and win,online football,bet online,soccer bets,champions league,barclays premier league,football betting site'
                }
            ]
        }
    },
    {
        path: '/faq',
        name: 'faq',
        component: Faq,
        meta: {
            title: 'FalconBet FAQs | Bet on sports online and win big| bet and win',
            metaTags: [
                {
                    name: 'description',
                    content: 'Here are detailed answers to the most frequently asked question about FalconBet  sports betting online .'
                },
                {
                    name: 'keywords',
                    content: 'FAQs, Betting online, online sports betting FAQs, FalconBet account,FalconBet paybill number, Mpesa,bet slip, place bet.'
                }
            ]
        }
    },
    {
        path: '/gifts',
        name: 'gifts',
        component: Gift,
        meta: {
            title: 'FalconBet Gift',
            metaTags: [
                {
                    name: 'title',
                    content: 'Gift'
                },
                {
                    name: 'description',
                    content: 'FalconBet Gift – Enjoy our Ksh 2000 Welcome Gift when you register with us '
                },
                {
                    name: 'keywords',
                    content: 'Welcome Bonus, Welcome Gift, Bonus, Gifts, Freebet'
                }
            ]
        },
    },
    {
        path: '/deposit-how-to',
        name: 'deposit-how-to',
        component: DepositHowTo,
        meta: {
            title: 'FalconBet Deposit | Bet on sports online and win big| bet and win',
            metaTags: [
                {
                    name: 'description',
                    content: 'How to Deposit'
                },
                {
                    name: 'keywords',
                    content: 'Gifts, Bonus, FAQs, Betting online, online sports betting FAQs, FalconBet account,FalconBet paybill number, Mpesa,bet slip, place bet.'
                }
            ]
        }
    },
    {
        path: '/howto',
        name: 'help',
        component: Help,
        meta: {
            title: 'FalconBet Help | How To Place a Bet | Online Sports Betting ',
            metaTags: [
                {
                    name: 'description',
                    content: 'Not sure how to start your online sports betting? Here is how to start your online betting game, sign up to join the player community started with FalconBet.'
                },
                {
                    name: 'keywords',
                    content: 'football online , betting , single bet, odds , bet id, sport betting,scores, Get in the game'
                }
            ]
        }
    },
    {
        path: '/responsible-gaming',
        name: 'responsible',
        component: Responsible
    },
    {
        path: '/reset/:msisdn/:code',
        name: 'reset',
        component: Reset,
    },
    {
        path: '/booklet',
        name: 'booklet',
        component: Booklet,
    },
    {
        path: '/jackpot-games',
        name: 'jackpot',
        component: Jackpot,
        meta: {
            title: 'Fastest Kenya Online Mega Jackpots Platform | FalconBet',
            metaTags: [
                {
                    name: 'description',
                    content: 'Fastest Kenya Online Mega Jackpots Platform | FalconBet'
                },
                {
                    name: 'keywords',
                    content: 'jackpot, daily Jackpot, jackpot bonus, jackpot predictions, mega Jackpot, midweek jackpot, midweek jackpot description, jackpot result'
                }
            ]
        }
    },
    {
        path: '/tips',
        name: 'tips',
        component: Tips,
    },
    {
        path: '/sharebet',
        name: 'sharebet',
        component: Sharebet,
    },
    {
        path: '/bet-history',
        name: 'CasinoHistory',
        component: CasinoHistory,
    },
    {
        path: '/referral',
        name: 'referral',
        component: Referral,
    },
    {
        path: '/affiliates',
        name: 'affiliates',
        component: Affiliate,
        meta: {
            hideHeader: true,
            title: 'falconbet.co.ke Sports Betting - Bet on Your Favorite Casino',
            ogTitle: 'falconbet.co.ke Sports Betting - Bet on Your Favorite Casiono',
            description: 'Bet on football, basketball, rugby, and more with falconbet.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Tucheze.com.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://falconbet.co.ke/android-chrome-512x512.png',
            ogUrl: 'https://falconbet.co.ke/affiliates'
        }
    },
    {
        path: '/cashafriq',
        name: 'Cashafriq',
        component: BustaPesa,
    },
    {
        path: '/formula-1',
        name: 'formula1',
        component: FormulaOne,
    },
    {
        path: '/terms&conditions-affiliates',
        name: 'TermsAffiliates',
        component: TermsAffiliates,
    },
    {
        path: '/bonustermsandconditions',
        name: 'BonusTnC',
        component: BonusTnC,
    },
    {
        path: '/offers',
        name: 'offers',
        component: Offers,
    },
    {
        path: '/results',
        name: 'results',
        component: Results,
    },
    {
        path: '/livescore',
        name: 'livescore',
        component: Livescore,
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
    },
    {
        path: '*',
        name: '404',
        component: Home,
        meta: {
            title: 'FalconBet |  Your Best sports betting site| Fast play Fast win',
            metaTags: [
                {
                    name: 'title',
                    content: 'FalconBet |  Your Best sports betting site| Fast play Fast win'
                },
                {
                    name: 'description',
                    content: 'FalconBet | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    name: 'keywords',
                    content: 'FalconBet, online betting , soccer,basketball, Bet, football bet online,place football bets online, place your bet,pre-game, live bet, how to build a bet.'
                },
                {
                    property: 'og:description',
                    content: 'FalconBet | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    property: 'twitter:description',
                    content: 'FalconBet | Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    property: 'twitter:title',
                    content: 'FalconBet |  Your Best sports betting site| Fast play Fast win'
                },
                {
                    property: 'og:title',
                    content: 'FalconBet |  Your Best sports betting site| Fast play Fast win'
                },
                {
                    property: 'og:url',
                    content: 'FalconBet.co.ke'
                },
                {
                    property: 'og:type',
                    content: 'Website'
                }
            ]
        }
    },
    //    casino games
    {
        path: '/casino',
        name: 'casino',
        component: Casino,
        meta: {
            title: 'FalconBet Casino',
            metaTags: [
                {
                    name: 'description',
                    content: 'Enjoy over 500 Casino games from, live casino games to jackpot Casino games, classic slots, scratch cards, poker and more '
                },
                {
                    name: 'keywords',
                    content: 'Casino, Online Casino, online real Casino, Online gaming Casino, Best online Kenya Casino, best online casino, top online Casino, online site casino, New casino, jackpot casino, video slots, classic slots, poker, Scratch cards, slots.'
                }
            ]
        }
    },
    {
        path: '/virtuals',
        name: 'virtuals',
        component: Virtuals,
        meta: {
            title: 'FalconBet Casino',
            metaTags: [
                {
                    name: 'description',
                    content: 'Enjoy over 500 Casino games from, live casino games to jackpot Casino games, classic slots, scratch cards, poker and more '
                },
                {
                    name: 'keywords',
                    content: 'Online casino, online real casinos, best online Kenyan Casino, online playing casino, best online kenya casino, online sites casino, online new casino, best online gaming casino casino best online sites, top casino site online, casino live, casino live online,  casino live games, casino site, live casino entertainment, live casino websites, casino real money'
                }
            ]
        }
    },
    {
        path: '/casino/live',
        name: 'livecasino',
        component: LiveCasino,
        meta: {
            title: 'FalconBet Casino',
            metaTags: [
                {
                    name: 'description',
                    content: 'Enjoy over 500 Casino games from, live casino games to jackpot Casino games, classic slots, scratch cards, poker and more '
                },
                {
                    name: 'keywords',
                    content: 'Online casino, online real casinos, best online Kenyan Casino, online playing casino, best online kenya casino, online sites casino, online new casino, best online gaming casino casino best online sites, top casino site online, casino live, casino live online,  casino live games, casino site, live casino entertainment, live casino websites, casino real money'
                }
            ]
        }
    },
    {
        path: '/casino-games',
        name: 'casinogames',
        component: CasinoGames,
        meta: {
            hideMenu: true,
            title: 'FalconBet Casino',
            metaTags: [
                {
                    name: 'description',
                    content: 'Enjoy over 500 Casino games from, live casino games to jackpot Casino games, classic slots, scratch cards, poker and more '
                },
                {
                    name: 'keywords',
                    content: 'Online casino, online real casinos, best online Kenyan Casino, online playing casino, best online kenya casino, online sites casino, online new casino, best online gaming casino casino best online sites, top casino site online, casino live, casino live online,  casino live games, casino site, live casino entertainment, live casino websites, casino real money'
                }
            ]
        }
    },
    {
        path: '/rocketman',
        name: 'rocketman',
        component: Rocketman,
        meta: {
            title: 'FalconBet Casino',
            metaTags: [
                {
                    name: 'description',
                    content: 'Enjoy over 500 Casino games from, live casino games to jackpot Casino games, classic slots, scratch cards, poker and more '
                },
                {
                    name: 'keywords',
                    content: 'Online casino, online real casinos, best online Kenyan Casino, online playing casino, best online kenya casino, online sites casino, online new casino, best online gaming casino casino best online sites, top casino site online, casino live, casino live online,  casino live games, casino site, live casino entertainment, live casino websites, casino real money'
                }
            ]
        }
    },
    {
        path: '/fastballs',
        name: 'fastballs',
        component: Fastballs,
        meta: {
            title: 'FalconBet Casino',
            metaTags: [
                {
                    name: 'description',
                    content: 'Enjoy over 500 Casino games from, live casino games to jackpot Casino games, classic slots, scratch cards, poker and more '
                },
                {
                    name: 'keywords',
                    content: 'Online casino, online real casinos, best online Kenyan Casino, online playing casino, best online kenya casino, online sites casino, online new casino, best online gaming casino casino best online sites, top casino site online, casino live, casino live online,  casino live games, casino site, live casino entertainment, live casino websites, casino real money'
                }
            ]
        }
    },
    {
        path: '/smartsoft',
        name: 'smartsoft',
        component: Smartsoft,
        meta: {
            title: 'FalconBet Casino',
            metaTags: [
                {
                    name: 'description',
                    content: 'Enjoy over 500 Casino games from, live casino games to jackpot Casino games, classic slots, scratch cards, poker and more '
                },
                {
                    name: 'keywords',
                    content: 'Online casino, online real casinos, best online Kenyan Casino, online playing casino, best online kenya casino, online sites casino, online new casino, best online gaming casino casino best online sites, top casino site online, casino live, casino live online,  casino live games, casino site, live casino entertainment, live casino websites, casino real money'
                }
            ]
        }
    },
    {
        path: '/jetsafi',
        name: 'jetsafi',
        component: Jetsafi,
        meta: {
            title: 'FalconBet Casino',
            metaTags: [
                {
                    name: 'description',
                    content: 'Enjoy over 500 Casino games from, live casino games to jackpot Casino games, classic slots, scratch cards, poker and more '
                },
                {
                    name: 'keywords',
                    content: 'Online casino, online real casinos, best online Kenyan Casino, online playing casino, best online kenya casino, online sites casino, online new casino, best online gaming casino casino best online sites, top casino site online, casino live, casino live online,  casino live games, casino site, live casino entertainment, live casino websites, casino real money'
                }
            ]
        }
    },
    {
        path: '/stp',
        name: 'stp',
        component: Stp,
        meta: {
            title: 'FalconBet Casino',
            metaTags: [
                {
                    name: 'description',
                    content: 'Enjoy over 500 Casino games from, live casino games to jackpot Casino games, classic slots, scratch cards, poker and more '
                },
                {
                    name: 'keywords',
                    content: 'Online casino, online real casinos, best online Kenyan Casino, online playing casino, best online kenya casino, online sites casino, online new casino, best online gaming casino casino best online sites, top casino site online, casino live, casino live online,  casino live games, casino site, live casino entertainment, live casino websites, casino real money'
                }
            ]
        }
    },
    {
        path: '/booming',
        name: 'Booming',
        component: Booming,
        meta: {
            title: 'FalconBet Casino',
            metaTags: [
                {
                    name: 'description',
                    content: 'Enjoy over 500 Casino games from, live casino games to jackpot Casino games, classic slots, scratch cards, poker and more '
                },
                {
                    name: 'keywords',
                    content: 'Online casino, online real casinos, best online Kenyan Casino, online playing casino, best online kenya casino, online sites casino, online new casino, best online gaming casino casino best online sites, top casino site online, casino live, casino live online,  casino live games, casino site, live casino entertainment, live casino websites, casino real money'
                }
            ]
        }
    },
    {
        path: '/onlyplay',
        name: 'Onlyplay',
        component: Onlyplay,
        meta: {
            title: 'FalconBet Casino',
            metaTags: [
                {
                    name: 'description',
                    content: 'Enjoy over 500 Casino games from, live casino games to jackpot Casino games, classic slots, scratch cards, poker and more '
                },
                {
                    name: 'keywords',
                    content: 'Online casino, online real casinos, best online Kenyan Casino, online playing casino, best online kenya casino, online sites casino, online new casino, best online gaming casino casino best online sites, top casino site online, casino live, casino live online,  casino live games, casino site, live casino entertainment, live casino websites, casino real money'
                }
            ]
        }
    }

];