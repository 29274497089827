import axios from 'axios';

const bettingserve = axios.create({
    baseURL: process.env.VUE_APP_BASE_BETTING_URL,
});

// Set default headers for POST requests
bettingserve.defaults.headers.post['Content-Type'] = 'application/json';
bettingserve.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

export default bettingserve;
